import {
    FETCH_WEBHOOKS_SUCCESS,
    FETCH_WEBHOOKS_FAIL,
    INIT_WEBHOOK_MODAL,
    FETCH_MANAGE_WEBHOOK,
    FETCH_MANAGE_WEBHOOK_SUCCESS,
    FETCH_MANAGE_WEBHOOK_FAIL, 
    FETCH_DELETE_WEBHOOK,
    FETCH_DELETE_WEBHOOK_SUCCESS,
    FETCH_DELETE_WEBHOOK_FAIL,
    FETCH_ALL_WEBHOOKS,
    FETCH_ALL_WEBHOOKS_SUCCESS,
    FETCH_ALL_WEBHOOKS_FAIL,
    FETCH_WEBHOOKS,
} from './_types';

const INITIAL_STATE = {
    pagination: { page: 1, total: 0, page_size: 1, page_count: 1 },
    sorting: { order_field: 'created_at', order_dir: 'ASC' },
    webhooks: [],
    initWebhook: {},
    actionTriggered: false
};

export default function managementWebHooksReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_WEBHOOKS:
            return state;
        case FETCH_WEBHOOKS_SUCCESS:
            return {
                ...state,
                pagination: {
                    page:
                        action.payload.pagination.total === 0
                            ? 0
                            : action.payload.pagination.page >
                              action.payload.pagination.page_count
                            ? 1
                            : action.payload.pagination.page,
                    total: action.payload.pagination.total,
                    page_size: action.payload.pagination.page_size,
                    page_count: action.payload.pagination.page_count
                },
                webhooks: action.payload.webhooks,
                sorting: action.payload.sorting
            };
        case FETCH_WEBHOOKS_FAIL:
            return { ...state, error: action.payload };
        case INIT_WEBHOOK_MODAL:
            return {
                ...state,
                initWebhook: action.payload
            };
        case FETCH_MANAGE_WEBHOOK:
        case FETCH_MANAGE_WEBHOOK_SUCCESS:
        case FETCH_MANAGE_WEBHOOK_FAIL:
            return state;
        case FETCH_ALL_WEBHOOKS:
            return state;
        case FETCH_ALL_WEBHOOKS_SUCCESS:
            return {
                ...state,
                webhooks: action.payload
            };
        case FETCH_ALL_WEBHOOKS_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}
