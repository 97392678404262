import { combineReducers } from 'redux';

import users from './users/_reducers';

import webhooks from './webhooks/_reducers';

const management = combineReducers({
  users: users,
  webhooks: webhooks
});

export default management;
