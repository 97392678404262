import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { ModalBody, ModalFooter, Button } from 'reactstrap';

import * as _ from  'lodash';

import RenderField from '../../common/components/RenderField';
import RenderReactSelect from '../../common/components/RenderReactSelect';
import RenderToggle from '../../common/components/RenderToggle';
import RenderCheckbox from '../../common/components/RenderCheckbox';
import UploadField from '../../common/components/UploadField';
import RenderRedactor from '../../common/components/RenderRedactor';
import ReactTelInput from 'react-telephone-input';

import { email, isBoolean, required } from '../../helpers/validate';

import { fetchManageWebhook } from './_actions';
import alerts from 'locales/alerts';
import { warning } from 'react-notification-system-redux';
import { param } from 'jquery';
import KeyValuePairsInput from "../../common/components/KeyValuePairsInput";
import CreatableMulti from "../../common/components/RenderReactMultiSelect";
import {yachtCharterStates, yachtStates} from "../../helpers/variables";

const form = reduxForm({
    form: 'manage_webhook'
});

let def = 'en';

class ManageWebhook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [],
            locales: []
        }
        this.state.locales = formatRawLocales(this.props.initialValues.locales ?? []);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleLocaleChange = this.handleLocaleChange.bind(this);
    }

    handleFormSubmit(formProps) {
        let params = {
            enabled: (formProps.enabled ?? 0 == 1 ? 1 : 0),
            name: formProps.name,
            url: formProps.url,
            headers: [],
            locales: [],
            id: null
        };
        params.headers.push(...this.state.headers);
        params.locales = this.state.locales;
        if (formProps.id) {
            params.id = formProps.id
        }


        this.props.fetchManageWebhook(params).then((data) => {
            if (data) {
                this.props.refetchWebhooks();
                this.props.toggle();
            }
        });
    }

    handleHeadersChange(headers) {
        this.setState({headers});
    }

    handleLocaleChange(locales) {
        this.setState({locales})
    }

    // onChangeRole = (role) => {
    //     this.setState({ selectedRole: role });
    // };


    render() {
        const { handleSubmit, submitting, invalid } = this.props;

        const headers = _.isEmpty(this.props.initialValues.headers) ? [] : this.props.initialValues.headers;
        const locales =  formatRawLocales(['en', 'ru']);

        return (
            <form
                className="modal-wrapper"
                onSubmit={handleSubmit(this.handleFormSubmit)}
            >
                <ModalBody className="modal-body">
                    <div className="modal-form-wrap">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 mb-1 mt-1">
                                <Field
                                    name="name"
                                    type="name"
                                    placeholder="Name"
                                    validate={[required]}
                                    component={RenderField}
                                />

                                <Field
                                    name="url"
                                    type="text"
                                    placeholder="URL"
                                    validate={[required]}
                                    component={RenderField}
                                />
                                <Field
                                    name="enabled"
                                    label="ENABLED"
                                    component={RenderToggle}
                                    className="mt-1"
                                />

                                <div className="col-lg-12 col-md-12 col-12 mt-select mb-2">
                                <CreatableMulti
                                    placeholder="Choose Webhook locales"
                                    options={
                                        locales
                                    }
                                    onChange={this.handleLocaleChange}
                                    value={this.state.locales}

                                />
                                </div>
                                <KeyValuePairsInput headers={headers} onChange={this.handleHeadersChange} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={invalid || submitting}
                    >
                        Save
                    </Button>{' '}
                    <Button
                        type="button"
                        color="secondary"
                        disabled={submitting}
                        onClick={this.props.toggle}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchManageWebhook: (e) => dispatch(fetchManageWebhook(e)),
        showWarning: (options) => dispatch(warning(options))
    };
}


function formatRawLocales(locales) {
    return _.map(locales, (l) => ({
        value: l.value ?? l,
        label: l.label ?? _.capitalize(l)
    }));
}

function mapStateToProps(state) {

    return {
        initialValues: {
            id: state.management.webhooks.initWebhook.id,
            name: state.management.webhooks.initWebhook.name,
            url: state.management.webhooks.initWebhook.url,
            headers: state.management.webhooks.initWebhook.headers,
            locales: formatRawLocales(state.management.webhooks.initWebhook.locales ?? []),
            enabled:
                state.management.webhooks.initWebhook.enabled &&
                state.management.webhooks.initWebhook.enabled === 1
                    ? true
                    : false
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form(ManageWebhook));
