
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';
import Moment from 'react-moment';

import Header from '../common/header/Header';

import SimpleTableActions from '../common/components/SimpleTableActions';
import SimpleTable from '../common/components/SimpleTable';
import SimpleModal from '../common/components/SimpleModal';

import RenderToggle from '../common/components/RenderToggle';

import DeleteConfirmation from '../common/components/DeleteConfirmation';

import management from '../assets/svgs/management.svg';
import add from '../assets/svgs/add.svg';
import edit from '../assets/svgs/edit.svg';
import deleteSVG from '../assets/svgs/delete.svg';

import {
    fetchWebhooks,
    initWebhookModal,
    fetchManageWebhook,
    fetchDeleteWebhook,
} from './webhooks/_actions';

import ManageWebhook from './webhooks/ManageWebhook';

class Management extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                parent: management,
                items: [
                    <NavLink exact to="/management">
                        Users
                    </NavLink>,
                    <NavLink exact to="/management/webhooks">
                        Webhooks
                    </NavLink>
                ]
            },
            headers: [
                {
                    name: 'name',
                    label: 'Name',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'url',
                    label: 'Url',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'enabled',
                    label: 'Enabled',
                    sort: false,
                    defaultSort: ''
                },
                {
                    name: 'created_at',
                    label: 'Created',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'updated_at',
                    label: 'Updated',
                    sort: true,
                    defaultSort: 'DESC'
                },
                {
                    name: 'action',
                    label: 'Action',
                    sort: false,
                    defaultSort: ''
                }
            ],
            modalHeader: 'Create new webhook',
            modalOpen: false,
            modalManage: (
                <ManageWebhook
                    toggle={this.toggleModal.bind(this)}
                    refetchWebhooks={this.refetchWebhooks.bind(this)}
                />
            ),
            modalDelete: (
                <DeleteConfirmation
                    toggle={this.toggleModal.bind(this)}
                    action={this.deleteWebhook.bind(this)}
                    body=""
                />
            ),
            defaultModal: null
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.props.fetchWebhooks({
            page: 1,
            order_field: 'created_at',
            order_dir: 'DESC',
            page_size: 25
        });
        this.setState({ defaultModal: this.state.modalManage });
    }

    tableChange(key, newValue) {
        let params = {
            page: key !== 'page' ? 1 : newValue,
            order_dir:
                key !== 'page' && key !== 'page_size'
                    ? newValue
                    : this.props.webhooks.sorting.order_dir,
            order_field:
                key !== 'page' && key !== 'page_size'
                    ? key
                    : this.props.webhooks.sorting.order_field,
            page_size:
                key === 'page_size'
                    ? newValue
                    : this.props.webhooks.pagination.page_size
        };

        this.props.fetchWebhooks(params);
    }

    refetchWebhooks(page) {
        
        const propsPage =  this.props.webhooks.pagination.page;
        this.props.fetchWebhooks({
            page: page ? page : (propsPage == 0 ? 1 : propsPage),
            order_field: this.props.webhooks.sorting.order_field,
            order_dir: this.props.webhooks.sorting.order_dir,
            page_size: this.props.webhooks.pagination.page_size
        });
    }

    createModalClick() {
        this.setState({ defaultModal: this.state.modalManage });
        this.setState({ modalHeader: 'Create new webhook' });

        this.props.initWebhookModal({});
        this.toggleModal();
    }

    editModalClick(webhook) {
        this.setState({ defaultModal: this.state.modalManage });
        this.setState({ modalHeader: 'Edit webhook' });

        this.props.initWebhookModal(webhook);
        this.toggleModal();
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    activeToggle(event, webhook) {
        
        let params = {
            id: webhook.id,
            enabled: event.target.value == 0 ? 1 : '0'
        };

        
        this.props.fetchManageWebhook(params).then(data => {
            
            if (data) {
                this.refetchWebhooks();
            }
        });
    }

    deleteModal(webhook) {
        this.props.initWebhookModal(webhook).then(() => {
            this.setState({
                modalDelete: (
                    <DeleteConfirmation
                        toggle={this.toggleModal.bind(this)}
                        action={this.deleteWebhook.bind(this)}
                        body={'You are about to delete webhook ' + webhook.name + '.'}
                    />
                )
            });
            this.setState({ defaultModal: this.state.modalDelete });
            this.setState({ modalHeader: 'Delete webhook' });

            this.toggleModal();
        });
    }

    deleteWebhook() {
        this.props
            .fetchDeleteWebhook({ id: this.props.initWebhook.id })
            .then(data => {
                if (data) {
                    if (this.props.webhooks.webhooks.length === 1)
                        this.refetchWebhooks(
                            this.props.webhooks.pagination.page - 1 > 0
                                ? this.props.webhooks.pagination.page - 1
                                : 1
                        );
                    else this.refetchWebhooks();
                }
            });
    }

    render() {
        return (
            <div className="content-inner-wrapper">
                <Header menu={this.state.menu} history={this.props.history} />

                <div className="content-inner">
                    <div className="row">
                        <div className="col-xl-9 col-12">
                            <SimpleTableActions
                                label="Create new webhook"
                                icon={add}
                                onClick={() => this.createModalClick()}
                            />
                            <SimpleTable
                                tableChange={this.tableChange.bind(this)}
                                paging={this.props.webhooks.pagination}
                                colWidths={[
                                    2,
                                    3,
                                    '2 text-center',
                                    '2 text-center',
                                    '2 text-center',
                                    '1 text-center'
                                ]}
                                headers={this.state.headers}
                                rowData={this.props.webhooks.webhooks.map(value => {
                                    return [
                                        <div>{value.name}</div>,
                                        <div>{value.url}</div>,
                                        <div className="td-toggle">
                                            <RenderToggle
                                                name={'enabled' + value.id}
                                                input={{
                                                    value: value.enabled,
                                                    onChange: (event, v) =>
                                                        this.activeToggle(
                                                            event,
                                                            value
                                                        )
                                                }}
                                            />
                                        </div>,
                                        <div>
                                            {value.created_at &&
                                                (moment(
                                                    value.created_at
                                                ).format('YYYY-MM-DD') ===
                                                moment().format(
                                                    'YYYY-MM-DD'
                                                ) ? (
                                                    <Moment fromNow ago>
                                                        {moment(
                                                            value.created_at
                                                        )}
                                                    </Moment>
                                                ) : (
                                                    moment(
                                                        value.created_at
                                                    ).format('DD/MM/YYYY HH:mm')
                                                ))} ago
                                        </div>,
                                        <div>
                                            {value.updated_at &&
                                                (moment(
                                                    value.updated_at
                                                ).format('YYYY-MM-DD') ===
                                                moment().format(
                                                    'YYYY-MM-DD'
                                                ) ? (
                                                    <Moment fromNow ago>
                                                        {moment(
                                                            value.updated_at
                                                        )}
                                                    </Moment>
                                                ) : (
                                                    moment(
                                                        value.updated_at
                                                    ).format('DD/MM/YYYY HH:mm')
                                                ))} ago
                                        </div>,
                                        <ul className="list-unstyled table-td-actions">
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        this.editModalClick(
                                                            value
                                                        )
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={edit}
                                                        style={{ height: 24 }}
                                                    />
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className="btn btn-td-action"
                                                    onClick={() =>
                                                        this.deleteModal(value)
                                                    }
                                                >
                                                    <ReactSVG
                                                        path={deleteSVG}
                                                        style={{ height: 24 }}
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                    ];
                                })}
                            />
                        </div>
                    </div>
                </div>

                <SimpleModal
                    header={this.state.modalHeader}
                    body={this.state.defaultModal}
                    modalOpen={this.state.modalOpen}
                    toggle={this.toggleModal.bind(this)}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchWebhooks: e => dispatch(fetchWebhooks(e)),
        initWebhookModal: e => dispatch(initWebhookModal(e)),
        fetchManageWebhook: e => dispatch(fetchManageWebhook(e)),
        fetchDeleteWebhook: e => dispatch(fetchDeleteWebhook(e))
    };
}

function mapStateToProps(state) {
    
    return {
        webhooks: state.management.webhooks,
        initWebhook: state.management.webhooks.initWebhook
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Management);
