import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { NavLink } from 'react-router-dom';

import add from '../../assets/svgs/add.svg';
import ClearWebsiteCache from './clearWebsiteCache/ClearWebsiteCache';
import { isWebsiteAdmin } from '../../infrastructure/helpers/roles';

class AdminActions extends Component {
    render() {
        return (
            <div
                className={`${
                    this.props.classname ? this.props.classname : ''
                } extra-table-actions`}
            >
                <NavLink
                    className="btn btn-blue"
                    to="/yachts/create"
                    style={{
                        maxWidth: '101px',
                        minWidth: '100px',
                        height: '32px',
                        color: '#fff',
                        fontSize: '1.2rem'
                    }}
                >
                    <ReactSVG path={add} style={{ height: 12 }} />
                    Add Νew
                </NavLink>
            </div>
        );
    }
}

export default AdminActions;
