import React, { Component } from "react";
import { Button, Input, FormGroup, Row, Col } from "reactstrap";

class KeyValuePairsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [...this.props.headers], // Initial empty row
        };
    }


    // componentDidUpdate(prevProps) {
    //     if (prevProps.headers !== this.props.headers) {
    //         this.setState({ headers: this.props.headers });
    //     }
    // }

    // Handle change in input fields
    handleChange = (index, field, event) => {
        const newHeaders = [...this.state.headers];
        newHeaders[index][field] = event.target.value;
        this.setState({ headers: newHeaders }, () => {
            this.props.onChange(newHeaders); // Pass updated headers to parent
        });
    };

    // Add a new row
    addHeader = () => {
        this.setState(
            (prevState) => ({
                headers: [...prevState.headers, { key: "", value: "" }],
            }),
            () => {
                this.props.onChange(this.state.headers);
            }
        );
    };

    // Remove a row
    removeHeader = (index) => {
        const newHeaders = this.state.headers.filter((_, i) => i !== index);
        this.setState({ headers: newHeaders }, () => {
            this.props.onChange(newHeaders);
        });
    };

    render() {
        return (
            <div>
                <h5>Custom Request Headers</h5>
                {this.state.headers.map((header, index) => (
                    <Row key={index} className="mb-2">
                        <Col md={5}>
                            <Input
                                type="text"
                                placeholder="Key"
                                value={header.key}
                                onChange={(e) => this.handleChange(index, "key", e)}
                            />
                        </Col>
                        <Col md={5}>
                            <Input
                                type="text"
                                placeholder="Value"
                                value={header.value}
                                onChange={(e) => this.handleChange(index, "value", e)}
                            />
                        </Col>
                        <Col md={2}>
                            <Button color="danger" onClick={() => this.removeHeader(index)}>-</Button>
                        </Col>
                    </Row>
                ))}
                <Button color="primary" onClick={this.addHeader}>+ Add Header</Button>
            </div>
        );
    }
}

export default KeyValuePairsInput;
