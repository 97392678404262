export const FETCH_WEBHOOKS = 'fetch_webhooks',
  FETCH_WEBHOOKS_SUCCESS = 'fetch_webhooks_success',
  FETCH_WEBHOOKS_FAIL = 'fetch_webhooks_fail',
  INIT_WEBHOOK_MODAL = 'init_webhook_modal',
  FETCH_MANAGE_WEBHOOK = 'fetch_manage_webhook',
  FETCH_MANAGE_WEBHOOK_SUCCESS = 'fetch_manage_webhook_success',
  FETCH_MANAGE_WEBHOOK_FAIL = 'fetch_manage_webhook_fail',
  FETCH_DELETE_WEBHOOK = 'fetch_delete_webhook',
  FETCH_DELETE_WEBHOOK_SUCCESS = 'fetch_delete_webhook_success',
  FETCH_DELETE_WEBHOOK_FAIL = 'fetch_delete_webhook_fail',
  FETCH_ALL_WEBHOOKS = 'fetch_all_webhook',
  FETCH_ALL_WEBHOOKS_SUCCESS = 'fetch_all_webhook_success',
  FETCH_ALL_WEBHOOKS_FAIL = 'fetch_all_webhook_fail';
