import axios from 'axios';

import {
  FETCH_WEBHOOKS,
  FETCH_WEBHOOKS_SUCCESS,
  FETCH_WEBHOOKS_FAIL,
  INIT_WEBHOOK_MODAL,
  FETCH_MANAGE_WEBHOOK,
  FETCH_MANAGE_WEBHOOK_SUCCESS,
  FETCH_MANAGE_WEBHOOK_FAIL,
  FETCH_DELETE_WEBHOOK,
  FETCH_DELETE_WEBHOOK_SUCCESS,
  FETCH_DELETE_WEBHOOK_FAIL,
  FETCH_ALL_WEBHOOKS,
  FETCH_ALL_WEBHOOKS_SUCCESS,
  FETCH_ALL_WEBHOOKS_FAIL
} from './_types';

import { API_URL } from '../../helpers/variables';
import { errorHandler, successHandler } from '../../helpers/alerts';
import { authConfig } from '../../helpers/authConfig';

export function fetchWebhooks(params) {
  return dispatch => {
    dispatch({ type: FETCH_WEBHOOKS });

    const config = {
      headers: authConfig(),
      params: params
    };

    axios
      .get(API_URL + '/webhooks', config)
      .then(response => {
        dispatch({
          type: FETCH_WEBHOOKS_SUCCESS,
          payload: {
            webhooks: response.data.webhooks,
            pagination: response.data.pagination,
            sorting: {
              order_dir: params.order_dir,
              order_field: params.order_field,
              page_size: params.page_size
            }
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_WEBHOOKS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function fetchAllWebhooks() {
  return dispatch => {
    dispatch({ type: FETCH_ALL_WEBHOOKS });

    const config = {
      headers: authConfig()
    };

    return axios
      .get(API_URL + '/webhooks', config)
      .then(response => {
        dispatch({ type: FETCH_ALL_WEBHOOKS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(error => {
        dispatch({ type: FETCH_ALL_WEBHOOKS_FAIL });
        errorHandler(dispatch, error.response);
      });
  };
}

export function initWebhookModal(webhook) {
  
  return dispatch => {
    dispatch({ type: INIT_WEBHOOK_MODAL, payload: webhook });

    return new Promise(function(resolve, reject) {
      resolve('Success!');
    });
  };
}

export function fetchManageWebhook(params) {
  return dispatch => {
    dispatch({ type: FETCH_MANAGE_WEBHOOK });

    const config = { headers: authConfig() };

    if (params.id) {
      return axios
        .put(API_URL + '/webhooks/' + params.id, params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_WEBHOOK_SUCCESS });
          successHandler(dispatch, 'manageWebhook');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_WEBHOOK_FAIL);
        });
    } else {
      return axios
        .post(API_URL + '/webhooks', params, config)
        .then(response => {
          dispatch({ type: FETCH_MANAGE_WEBHOOK_SUCCESS });
          successHandler(dispatch, 'manageWebhook');

          return response.data;
        })
        .catch(error => {
          errorHandler(dispatch, error.response, FETCH_MANAGE_WEBHOOK_FAIL);
        });
    }
  };
}

export function fetchDeleteWebhook(params) {
  return dispatch => {
    dispatch({ type: FETCH_DELETE_WEBHOOK });

    const config = { headers: authConfig() };

    return axios
      .delete(API_URL + '/webhooks/' + params.id, config)
      .then(response => {
        dispatch({ type: FETCH_DELETE_WEBHOOK_SUCCESS });
        successHandler(dispatch, 'deleteWebhook');

        return response.data;
      })
      .catch(error => {
        errorHandler(dispatch, error.response, FETCH_DELETE_WEBHOOK_FAIL);
      });
  };
}
