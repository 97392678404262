import axios from 'axios';

import {
    FETCH_INITIAL_STATE,
    FETCH_INITIAL_STATE_SUCCESS,
    FETCH_INITIAL_STATE_ERROR
} from './_types';

import { API_URL } from '../variables';
import { errorHandler } from '../alerts';
import { authConfig } from '../authConfig';

export function fetchInitialState() {
    return dispatch => {
        dispatch({ type: FETCH_INITIAL_STATE });

        const config = {
            headers: authConfig(),
            params: {}
        };

        return axios
            .get(API_URL + '/users/current', config)
            .then(response => {
                dispatch({
                    type: FETCH_INITIAL_STATE_SUCCESS,
                    payload: { user: response.data }
                });
            })
            .catch(error => {
                dispatch({ type: FETCH_INITIAL_STATE_ERROR });

                errorHandler(dispatch, error.response);
            });
    };
}
